import React, { Component } from 'react'
import {
  Badge,
  Box,
  Grid,
  Flex,
  Heading,
  Text,
  Stack
} from "@chakra-ui/react"

import { FormattedNumber } from 'react-intl'
import logo from "../../static/svg/logo.svg"
import { Link as GatsbyLink } from 'gatsby'

import { BiCamera } from 'react-icons/bi'

class AdCardLandscapeSmaller extends Component {
  displayThumbnail(images, alt) {
    if (!images || !images.url || !images.url.length) {
      return (
        <picture>
          <img loading="lazy" size='75px' width='75px' height='75px' src={logo} alt={alt} />
        </picture>
      )
    }
    else {
      return (
        <Box
          position="relative"
          w="75px"
          h="75px"
          borderRadius={'4px 0 0 4px'}
        >
          <picture >

            <source
              srcSet={
                // process.env.GATSBY_IMAGE_URL_PREFIX
                `${process.env.GATSBY_BASE_IMG_URL}`
                + images.url[0]
                + '?twic=v1/cover=150x150/format=heif/quality=50'
                // + '?quality=70&fit=cover&width=96&height=96&format=webp'
              }
              type="image/heif"
            />
            <source
              srcSet={
                // process.env.GATSBY_IMAGE_URL_PREFIX
                `${process.env.GATSBY_BASE_IMG_URL}`
                + images.url[0]
                + '?twic=v1/cover=150x150/format=webp/quality=50'
                // + '?quality=70&fit=cover&width=96&height=96&format=webp'
              }
              type="image/webp"
            />
            <source
              srcSet={
                // process.env.GATSBY_IMAGE_URL_PREFIX
                `${process.env.GATSBY_BASE_IMG_URL}`
                + images.url[0]
                + '?twic=v1/cover=150x150/format=jpeg/quality=50'
                // + '?quality=70&fit=cover&width=96&height=96&format=jpeg'
              }
              type="image/jpeg"
            />
            <img
              src={
                // process.env.GATSBY_IMAGE_URL_PREFIX
                `${process.env.GATSBY_BASE_IMG_URL}`
                + images.url[0]
                + '?twic=v1/cover=150x150/format=jpeg/quality=50'
                // + '?quality=70&fit=cover&width=96&height=96&format=jpg'
              }
              width={75}
              height={75}
              loading='lazy'
              alt={alt}
            />
          </picture>
          <Flex
            position="absolute" bottom="0" left="0" w={'100%'}
            justify="flex-end"
            align={'center'}
            borderRadius={'4px 0 0 4px'}
            p={1}
            background="linear-gradient(180deg, rgba(32, 32, 32, 0) 0%, rgba(26, 26, 26, 0.8) 100%)"
          >
            <Text
              as="span"
              fontSize="xs"
              color="white"
              pr={1}
            >
              {images.url.length}
            </Text>
            <BiCamera color='white' />
          </Flex>
        </Box>

      );
    }

  }
  render() {
    const { name, price, to, region, images, isNavigable } = this.props;
    return (

      <Grid
        templateColumns={{ base: "75px calc(100% - 75px)" }}




        className="item-card"
        cursor="pointer"
        as={isNavigable ? GatsbyLink : "article"}
        to={`${to}`}
        background="white"
        shadow="xs"
        borderRadius={"sm"}
        overflow="hidden"
        _hover={{
          boxShadow: "0 0 0px 2px rgba(0,66,218,.7)"
        }}
        onClick={!isNavigable ? event => {
          this.props.openAdDetail()
          // let relatedLink = event.target.closest('.item-card').querySelector('a');
          // relatedLink.click();
        } : null}



      >
        <Box overflow="hidden">
          {images ?
            this.displayThumbnail(images, name)
            :
            <picture>
              <img loading="lazy" m='4rem' src={logo} />
            </picture>
          }
        </Box>
        <Flex
          px={4}
          alignItems={'center'}
          overflow={'hidden'}
        >
          <Box>
            <Heading
              as='p'
              display="block"
              w={'100%'}
              fontWeight="400"
              fontSize={{ base: "md" }}
              pr="2rem"
              textOverflow="ellipsis"
              isTruncated>
              {name}
            </Heading>
            <Stack mt=".5rem" isInline>
              <Badge as="span" colorScheme="green" fontSize="sm">
                {(price > 0) ?
                  // <NumberFormat
                  //   value={price}
                  //   thousandSeparator={" "}
                  //   suffix=" €"
                  //   displayType="text"
                  // />

                  <FormattedNumber
                    value={this.props.currency ? Math.round(parseInt(price) * parseFloat(this.props.currency.rate)) : price}
                    format={this.props.currency ? this.props.currency.name : 'EUR'}
                    minimumFractionDigits={'0'}
                    maximumFractionDigits={'0'}
                  />

                  : "-"}
              </Badge>
              <Heading as='p' isTruncated fontSize="13px">{region}</Heading>
            </Stack>
          </Box>
        </Flex>
      </Grid>
    )
  }
}

export default AdCardLandscapeSmaller