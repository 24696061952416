import React from "react"
import { graphql } from 'gatsby'
import LayoutPublic from "../components/layouts/layoutPublic"
import { Box, Spinner, Flex } from "@chakra-ui/react"
import Seo from '../components/Seo/';
// import Header from "../components/header/index"
import AdsSummary from "../components/lists/adsSummary"
import HomeModelsList from "../components/lists/homeModelsList"
import BrandsList from "../components/lists/brandsList"
import HomeHero from "../components/HomeHero"
import AsyncAdsPanel from "../components/ad/AsyncAdsPanel"
import HrefLang from '../utils/HrefLang'
// import ReactMarkdown from 'react-markdown'
import Wrapper from '../components/Wrapper'
import Loadable from 'react-loadable'
import { Stack } from '@chakra-ui/react'
import Nav from "../components/nav";
import { SailorzBannerLoader } from "../components/Sailorz/Loader";
import { wrapperPadding } from "../utils/wrapperPadding";
// const PartnerNewsSummaryLoader = Loadable({
//   loader: () => import('../components/PartnerNews/Loader'),
//   loading() {
//     return <Box color='blue.400' p={10} textAlign='center'>Chargement</Box>
//   }
// })
var markdown = require("markdown").markdown;


const HomepageTpl = (props) => {
  const {
    ads,
    allAds,
    brands,
    cheapAds,
    expensiveAds,
    models
  } = props.data

  const { universID } = props.pageContext
  const slug = '/'
  const title = {
    "fr":
      "Bateaux d'occasion à vendre, achat/vente",
    "en":
      "Used boats for sale, price/buying/selling a boat"
  }
  const mainTitle = {
    "fr":
      "Bateaux d'occasion",
    "en":
      "Used boats ads "
  }
  const heroTitle = {
    "fr":
      "⚓ Wanaboat.fr, le site des meilleures petites annonces nautiques et bateaux occasion de tout l'internet. C'est ici que vous acheterez ou vendrez votre voilier d'occasion.",
    "en":
      "⚓ iWannaboat.com, the best nautical classified ads and second hand boats all over the internet. This is where you will buy or sell your second-hand sailboat."
  }
  const heroSubTitle = {
    "fr":
      "Fait avec passion ❤️ depuis 2007.",
    "en":
      "Made with passion ❤️ since 2020."
  }
  const description = {
    "fr": "Bateaux d'occasion au meilleur prix à vendre en France et en Europe, annonces prix, achat, vente. Wanaboat.fr spécialiste achat/vente bateaux d'occasion.",
    "en":
      "Used boats classifieds ads at the best price for sale in Europe, price listings, buy, sell. iWannaboat.com specialist buy/sell used boats."
  };
  return (
    <LayoutPublic lang={process.env.GATSBY_LANG}>
      <HrefLang slug={''} />

      <Seo
        title={
          title[process.env.GATSBY_LANG]
        }
        description={
          description[process.env.GATSBY_LANG]
        }
        slug={''}
      />

      <HomeHero
        universName={'Bateaux occasion'}
        nav={<Nav />}
      />

      <AdsSummary ads={ads} universName={''} orientation={'portrait'} />


      <Stack
        spacing={{ base: 2, lg: 4 }}
        {...wrapperPadding()}
      >

        <SailorzBannerLoader />

        <HomeModelsList models={models} universName={''} />
        <BrandsList brands={brands.edges} universName={''} limite={16} />
        {/* <PartnerNewsSummaryLoader /> */}
        <AsyncAdsPanel
          baseSlug={slug}
          minPrice={cheapAds.edges[0].node.price}
          maxPrice={expensiveAds.edges[0].node.price}
          universID={universID}
          models={models}
          isModelsMobileOpen={false}
          scrolling={false}
          switchViewForBrowsing={() => { this.setState({ isUserFilters: true }) }}
        />

        <Box
          className="wysiwyg"
          mt='3rem'
          p={{ base: '1rem', lg: '2rem' }}
          background='white'
          dangerouslySetInnerHTML={{
            "__html": markdown.toHTML(props.data.page.childContentfulPageBodyTextNode.body)
          }}
        />
      </Stack>
    </LayoutPublic>
  )
}

// 47E66HzdNcvENtKpQBrneI

export default HomepageTpl

export const pageQuery = graphql`
  query homepageQuery( $lang: String! ) {
    page: contentfulPage(
        contentful_id: { eq: "47E66HzdNcvENtKpQBrneI" }
        node_locale: { eq: $lang }
    ) {
        contentful_id
        title
        childContentfulPageBodyTextNode{
          body
        }
    }
    models: allContentfulModel(
      filter: {
        node_locale: {eq: $lang}
        isFeatured: {eq: true}
      }
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
            id
          name
          slug
          ad {
            id
            slug
          }
        }
      }
    }
    brands: allContentfulBrand(
      filter: {
        node_locale: { eq: $lang }
        isFeatured: {eq: true}
      }
    ) {
      edges {
        node {
          name
          slug
          contentful_id
          ad {
            id
            contentful_id
            slug
          }
        }
      }
    }
    allAds: allContentfulAd {
      edges {
        node {
          id
          refBrand{
            contentful_id
          }
        }
      }
    }
    expensiveAds: allContentfulAd(
      filter: {
        price: { gte: 100 }
        isBoat: { eq: true }
      }
      limit: 3
      sort: { fields: [price], order: DESC }
    ) {
      edges {
        node {
          refModel {
            name
          }
          id
          name
          slug
          price
        }
      }
    }
    cheapAds: allContentfulAd(
      filter: {
        price: { gte: 10 }
        isBoat: { eq: true }
      }
      limit: 3
      sort: { fields: [price], order: ASC }
    ) {
      edges {
        node {
          refModel {
            name
          }
          id
          name
          slug
          price
        }
      }
    }
    ads: allContentfulAd(
      limit: 12
      filter: { node_locale: {eq: $lang}, isPremium : { eq: true} }
      sort: { fields: [publicationDate], order: DESC }
    ) {
      edges {
        node {
          id
          contentful_id
          name
          slug
          price
          department
          region
          isBoat
          isSail
          isPart
          isTrolley
          refBrand { name }
          refModel { name }
          images { url }
          pictures { url }
          date(formatString: "Y")
          publicationDate(formatString: "D/M/Y")
          refUnivers {
            slug
          }
        }
      }
    }
  }
`;