import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { Box, Heading, Flex, Grid, Link, Image, Stack, Text, useColorModeValue } from '@chakra-ui/react'
import { FormattedMessage, useIntl } from 'react-intl'
import roundedShape from '../images/rounded-shape.svg'
import oldMarinerPng from '../images/old-mariner-1.png'
import oldMarinerWebp from '../images/old-mariner-1.webp'

import { BiRightArrowAlt } from 'react-icons/bi'
import Nav from './nav'
import { wrapperPadding } from '../utils/wrapperPadding'
const siteConfig = require("../../config.js");

const HomeHero = (props) => {
    const intl = useIntl()
    const name = intl.formatMessage({ id: 'main.used.boats' })

    const titleEn = <><Text as="span" color="blue.brand">Used boats for sale</Text>, sailing boats ads and nautical parts</>
    const titleFr = <><Text as="span" color="blue.brand">Bateaux d'occasion à vendre</Text>, annonces voiliers et matériel nautique</>
    const titles = {
        'en': titleEn,
        'fr': titleFr
    }

    const subTitleEn = <>Made with passion ❤️ and a 😷 since 2020.</>
    const subTitleFr = <>Fait avec ❤️&nbsp;&nbsp;et un 😷&nbsp;&nbsp;+ 💉💉💉</>
    const subTitles = {
        'en': subTitleEn,
        'fr': subTitleFr
    }


    return (
        <Grid
            templateColumns={{ lg: '1fr 500px' }}
            gap={{ lg: '0rem', xl: '4rem', xxl: '7.5rem', xxxl: '10rem' }}
            mb={{ base: 4, lg: 12 }}
            pt={{ base: 4, lg: 0 }}
            {...wrapperPadding()}
        >
            <Box
                my={{ base: 8, lg: 0 }}
                order={{ base: 2, lg: 1 }}

            >
                <Nav />

                <Stack
                    spacing={{ base: 4, lg: '2rem' }}
                    p={{ base: 0, lg: 0 }}
                    mt={{ base: 4, lg: 8 }}

                >
                    <Heading
                        as="h1"
                        fontSize={{ base: '26px', md: '3xl', lg: '3xl', xl: '4xl', xxxl: '5xl' }}
                        color={useColorModeValue('gray.700', 'whiteAlpha.800')}
                        mb={{ base: 0, lg: 5, xl: 10 }}
                    >
                        {titles[process.env.GATSBY_LANG]}
                        {/* <Text as="span" color="blue.brand">Bateaux d'occasion à vendre</Text>, annonces voiliers et matériel nautique */}
                    </Heading>
                    <Text
                        fontSize={{ base: '16px', lg: '20px', xl: '22px', xxl: '24px' }}
                    >
                        <FormattedMessage id="main.brand.moto" />{' '}
                        <Text
                            as="span"
                            fontWeight='500'
                            display={{ base: 'none', lg: 'inline' }}
                        >
                            <FormattedMessage id="main.brand.moto.2" />&nbsp;!
                        </Text>
                    </Text>
                    <Text
                        display={{ base: 'none', lg: 'block' }}
                        fontSize='20px'>
                        {subTitles[process.env.GATSBY_LANG]}
                    </Text>
                    <Flex>
                        <Flex
                            as={GatsbyLink}
                            to={siteConfig.links.publish[process.env.GATSBY_LANG]}
                            bgColor='primary'
                            borderRadius={'full'}
                            color='white'
                            px={3}
                            py={2}
                            alignItems='center'
                        >
                            <Text
                                as="span"
                            // background="linear-gradient(270deg, rgba(255, 255, 255, 0.48) 0%, rgba(255, 255, 255, 0.221538) 53.85%, rgba(255, 255, 255, 0) 100%), rgba(253, 255, 148, 0.69)"
                            >
                                <FormattedMessage id="cta.publish.with.univers" values={{ name: name.toLowerCase() }} />
                            </Text>
                            <BiRightArrowAlt />
                        </Flex>
                    </Flex>
                    {/* <Box>
                        <Button colorScheme='green' as={GatsbyLink} to={'/capitainerie/pronostiques-vendeeglobe-2020/'}>Qui va gagner le Vendée Globe 2020 ?</Button>
                    </Box> */}
                </Stack>
            </Box>
            <Box

                order={{ base: 1, lg: 2 }}
                position={{
                    // base: 'absolute',
                    lg: 'relative'
                }}
                // h={{ base: '50vh', lg: 'auto' }}
                // p='1rem'
                // w={{ base: '100%', lg: 'auto' }}
                background={{ lg: `url(${roundedShape}) no-repeat top left` }}
            >
                <picture>
                    <source srcSet={oldMarinerWebp} type="image/webp" />
                    <source srcSet={oldMarinerPng} type="image/png" />
                    <Image
                        src={oldMarinerPng}
                        p={0}
                        m={'auto'}
                        w='497px'
                        h='516px'
                        h={{ base: '100%', lg: 'auto' }}
                        objectFit="contain"
                        alt={intl.formatMessage({ id: 'main.sitename' })}
                        title={intl.formatMessage({ id: 'main.sitename' })}
                        loading='eager'
                        htmlWidth={'1'}
                        htmlHeight={'1'}
                    />
                </picture>
            </Box>
        </Grid>

    )
}

export default HomeHero