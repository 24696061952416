import React, { useState, useEffect, useContext } from 'react'
import {
    Heading,
    Text,
    SimpleGrid,
    Flex
} from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl';
import AdLoader from '../ad/AdLoader'

import AdCardLandscapeSmaller from '../ad/cardLandscapeSmaller'
import AdCardPortraitSmaller from '../ad/cardPortraitSmaller'
import Debug from '../../utils/Debug';
import { AdContext } from '../ad/adContext';
import { UserProvider } from '../userContext';
import { wrapperPadding } from '../../utils/wrapperPadding';

const AdsSummary = (props) => {
    const [currency, setCurrency] = useState(null)
    const adContext = useContext(AdContext);
    const userContext = useContext(UserProvider);

    useEffect(() => {
        let currency = JSON.parse(localStorage.getItem('currency'))
        setCurrency(currency)
    }, []);

    const ads = props.ads.edges ? props.ads.edges : props.ads;

    const Container = ({ children }) => (


        props.columns === 1 ?
            <SimpleGrid
                columns={props.columns ? props.columns : { base: 1, lg: 5, xxl: 5 }}
                gap="1rem"
                mb='1rem'
            >
                {children}
            </SimpleGrid>
            :
            <Flex
                wrap='nowrap'
                // overflowX={'scroll'}
                style={{
                    scrollSnapType: 'x mandatory',
                    scrollPadding: '20px'
                }}
                py={2}
                className='no-scrollbar'
                // {...wrapperPadding()}
                px={{ base: 4, lg: .5 }}

            >
                {children}
            </Flex>
    )

    return (

        props.ads ?
            <>
                <Text
                    as='div'
                    color="gray.800"
                    textTransform='uppercase'
                    fontSize='13px'
                    color='blue.600'
                    letterSpacing='.05rem'
                    display={{ base: "none", md: "block" }}
                >
                    <FormattedMessage id="header.h2.barely-docked" />
                    <Heading display="inline" as="h2" fontWeight="normal" fontSize={13}>
                        <FormattedMessage id="header.h2.last-univers-ads" values={{ name: props.universName }} />
                    </Heading>{' '}
                    <FormattedMessage id="header.h2.for-you" />&nbsp;!
                </Text>
                <Container>
                    {ads.map((edge, i) =>
                        props.orientation === 'portrait' ?
                            <AdCardPortraitSmaller
                                name={edge.node.name}
                                images={edge.node.pictures}
                                key={`landscapesmall-${edge.node.id}`}
                                region={edge.node.region}
                                price={edge.node.price}
                                to={edge.node.slug}
                                currency={currency}
                                ad={edge.node}
                                openAdDetail={() => {
                                    adContext.setAd(edge.node.contentful_id)
                                    // setAd(edge.node.contentful_id)
                                    // setAdSingleID(edge.node.contentful_id)
                                }}
                                index={i}
                            />
                            :
                            <AdCardLandscapeSmaller
                                name={edge.node.name}
                                images={edge.node.pictures}
                                key={`landscapesmall-${edge.node.id}`}
                                region={edge.node.region}
                                price={edge.node.price}
                                to={edge.node.slug}
                                currency={currency}
                                ad={edge.node}
                                openAdDetail={() => {
                                    adContext.setAd(edge.node.contentful_id)
                                    // setAd(edge.node.contentful_id)
                                    // setAdSingleID(edge.node.contentful_id)
                                }}
                                index={i}
                            />
                    )}
                </Container>
            </>
            : null

    )
}

export default AdsSummary